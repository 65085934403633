@if (control) {
  <mat-form-field>
    @if (label$ | async; as label) {
      <mat-label>{{ label }}</mat-label>
    }
    @if (multiSelect$ | async) {
      <mat-select multiple [formControl]="control">
        <div class="dropdown-list">
          @if ((searchable$ | async) && (filterInput$ | async)) {
            <mat-form-field class="mb-4">
              <div class="search-grid">
                <mat-icon svgIcon="cp-search" />
                <input matInput [formControl]="searchQuery" (keydown)="$event.stopPropagation()" />
                @if (searchQueryResettable$ | async) {
                  <mat-icon svgIcon="cp-close" (click)="clearSearchQuery()" />
                }
              </div>
            </mat-form-field>
          }
          <div class="dropdown-items">
            @for (
              option of optionsWithVisibilityStatus$ | async;
              track trackByValue($index, option)
            ) {
              <mat-option
                [value]="option.value"
                [class.hidden]="!option.visible"
                class="!py-2 h-auto"
                >{{ option.label }}</mat-option
              >
            }
          </div>
          <div class="mt-4">
            @if (allSelected$ | async) {
              <a (click)="deselectAll()">Alle abwählen</a>
            } @else {
              <a (click)="selectAll()">Alle auswählen</a>
            }
          </div>
        </div>
      </mat-select>
    } @else {
      <mat-select [formControl]="control">
        <div class="dropdown-list">
          <ng-content select="[masterSearch]" />
          @if ((searchable$ | async) && (filterInput$ | async)) {
            <mat-form-field>
              <div class="search-grid">
                <mat-icon svgIcon="cp-search" />
                <input matInput [formControl]="searchQuery" (keydown)="$event.stopPropagation()" />
                @if (searchQueryResettable$ | async) {
                  <mat-icon svgIcon="cp-close" (click)="clearSearchQuery()" />
                }
              </div>
            </mat-form-field>
          }
          <div class="dropdown-items">
            @if (!(required || searchQuery.value)) {
              <mat-option />
            }
            @for (
              option of optionsWithVisibilityStatus$ | async;
              track trackByValue($index, option)
            ) {
              <mat-option
                [value]="option.value"
                class="!py-2 h-auto"
                [class.hidden]="!option.visible"
                >{{ option.label }}</mat-option
              >
            }
          </div>
        </div>
      </mat-select>
    }
  </mat-form-field>
}
