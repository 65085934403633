import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { HeadlineComponent } from '../headline/headline.component';

interface UpdateAppDialogData {
  appName: string;
}

@Component({
  selector: 'ui-update-app-modal',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, HeadlineComponent],
  templateUrl: './update-app-modal.component.html',
  styleUrls: ['./update-app-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateAppModalComponent {
  readonly data = inject<UpdateAppDialogData | undefined>(MAT_DIALOG_DATA);
}
