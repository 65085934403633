import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  forwardRef,
  inject,
} from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface Filter<TColumnEnum> {
  column: TColumnEnum;
  filter: string;
}

@Component({
  selector: 'ui-search-filter',
  standalone: true,
  imports: [MatMenuModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, MatIconModule],
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFilterComponent),
      multi: true,
    },
  ],
})
export class SearchFilterComponent<TColumnEnum>
  extends ControlValueAccessorDirective<Filter<TColumnEnum>>
  implements OnInit
{
  private _destroyRef = inject(DestroyRef);
  @Input() column!: string;
  searchControl = new FormControl('');

  override ngOnInit(): void {
    super.ngOnInit();

    this.searchControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => {
      const filter = {
        filter: value,
        column: this.column,
      } as Filter<TColumnEnum>;
      this.onChange(filter);
    });
  }

  clearInput() {
    if (this.control.value.column != this.column) {
      this.searchControl.setValue('', { emitEvent: false });
    }
  }

  focusInput(input: HTMLInputElement): void {
    if (input) input.focus();
  }
}
