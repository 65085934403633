<ng-container [formGroup]="fg">
  <mat-form-field>
    <mat-label>{{ label }} (Min)</mat-label>
    <input matInput type="number" [formControl]="ctrl.min" />
    <mat-error ui-input-errors [control]="ctrl.min" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ label }} (Max)</mat-label>
    <input matInput type="number" [formControl]="ctrl.max" />
    <mat-error ui-input-errors [control]="ctrl.max" />
  </mat-form-field>
</ng-container>
