<ng-template #resetBtn>
  <button
    tabindex="-1"
    mat-button
    (click)="setDefaultCheckedItems()"
    [disabled]="control.value | map: isDefaultValue"
  >
    Auswahl zurücksetzen
  </button>
</ng-template>
<ng-template #showOnlyCheckedBtn>
  <button tabindex="-1" mat-button (click)="toggleShowOnlyChecked()">
    {{ (showOnlyChecked$ | async) ? 'Alle anzeigen' : 'Nur ausgewählte anzeigen' }}
  </button>
</ng-template>
<ng-template #toggleAllBtn>
  <button tabindex="-1" mat-button (click)="toggleAll()">
    {{ (toggleAll$ | async) ? 'Alle zuklappen' : 'Alle aufklappen' }}
  </button>
</ng-template>

@if (control && valueFormGroup$ | async; as fg) {
  <div>
    <!-- <pre>{{control.value | json}}</pre>
    <pre>{{fg.value | json}}</pre> -->
    <mat-form-field>
      <div class="flex gap-4">
        <mat-icon svgIcon="cp-search" />
        <input matInput [formControl]="searchQuery" />
        @if (searchQueryResettable$ | async) {
          <mat-icon svgIcon="cp-close" (click)="this.searchQuery.reset()" />
        }
      </div>
    </mat-form-field>
    <div class="w-full my-3 flex justify-between items-center">
      <ng-container *ngTemplateOutlet="showOnlyCheckedBtn" />
      <ng-container *ngTemplateOutlet="resetBtn" />
      <ng-container *ngTemplateOutlet="toggleAllBtn" />
    </div>
    <cdk-virtual-scroll-viewport class="selection-list" itemSize="62">
      @if ((showItemsCount$ | async) === 0) {
        <div class="text-center text-muted">Keine Einträge gefunden</div>
      }
      <ng-container *cdkVirtualFor="let item of items$ | async; trackBy: trackByValue">
        <!-- if parent -->
        @if (
          item.childrenCtrls && {
            checked: item.childrenCtrls | map: toValues$ | async | map: checkAllTrue,
            indeterminate: item.childrenCtrls | map: toValues$ | async | map: checkOneTrue
          };
          as tmp
        ) {
          <div class="drag-item" [class.active]="tmp.checked">
            <div class="flex justify-between items-center">
              <mat-checkbox
                [indeterminate]="tmp.indeterminate && !tmp.checked"
                [checked]="tmp.checked"
                (change)="markAllChildren($event, item.childrenCtrls)"
                [disabled]="!item.childrenCtrls.length"
              >
                {{ item.label }}
                ({{ item.childrenCtrls.length }})
              </mat-checkbox>
              @if ((showOnlyChecked$ | async) === false) {
                <mat-icon
                  class="cursor-pointer"
                  (click)="item.toggle$.next(!item.toggle$.value)"
                  [class.white-icon]="tmp.checked"
                  [svgIcon]="(item.toggle$ | async) ? 'cp-direction-up' : 'cp-direction-down'"
                />
              }
            </div>
          </div>
        }
        <!-- if children -->
        @if (item.ctrl) {
          <div class="drag-item ml-10" [class.active]="item.ctrl.value">
            <mat-checkbox [checked]="item.ctrl.value" (change)="markControl($event, item.ctrl)">
              {{ item.label }}
            </mat-checkbox>
          </div>
        }
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
}
