import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, map, shareReplay, switchMap } from 'rxjs';
import { SparteService } from '../ti-interfaces';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private _sparteService = inject(SparteService);

  get selectedProdukt(): string {
    const produkt = window.localStorage.getItem('produkt');
    return produkt ?? '';
  }

  set selectedProdukt(produktChange) {
    window.localStorage.setItem('produkt', produktChange);
    this.selectedProdukt$.next(produktChange);
  }

  selectedProdukt$ = new BehaviorSubject<string>(this.selectedProdukt);

  get selectedSparte(): string {
    const sparte = window.localStorage.getItem('sparte');
    return sparte ?? '';
  }

  set selectedSparte(sparteChange) {
    window.localStorage.setItem('sparte', sparteChange);
    this.selectedSparte$.next(sparteChange);
  }

  selectedSparte$ = new BehaviorSubject<string>(this.selectedSparte);

  sparteFeatures$ = this.selectedSparte$.pipe(
    switchMap(data => this._sparteService.getSparte({ sparteId: data })),
    map(sparte => sparte.features.value),
    shareReplay(1)
  );
}
