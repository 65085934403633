import type { Route } from '@angular/router';
import { AuthGuard } from '@comparit/core';
import { sparteGuardWithRedirect } from '../guards/sparte.guard';

const dashboard = {
  path: 'dashboard' as const,
  loadComponent: () =>
    import('./page-dashboard/page-dashboard.component').then(m => m.PageDashboardComponent),
  canActivate: [AuthGuard],
} satisfies Route;

const attributes = {
  path: 'attributes' as const,
  loadComponent: () =>
    import('./page-attribute/page-attribute.component').then(m => m.PageAttributeComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
  children: [
    {
      path: '' as const,
      loadComponent: () =>
        import('./page-attribute/attribute-list/attribute-list.component').then(
          m => m.AttributeListComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
      data: {
        layout: {
          large: true,
        },
      },
    },
    {
      path: 'add' as const,
      loadComponent: () =>
        import('./page-attribute/attribut-add/attribut-add.component').then(
          m => m.AttributAddComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: `groups` as const,
      loadComponent: () =>
        import('./page-attribute/page-attribute-groups/page-attribute-groups.component').then(
          m => m.PageAttributeGroupsComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
      children: [
        {
          path: `` as const,
          loadComponent: () =>
            import(
              './page-attribute/page-attribute-groups/attribute-groups-list/attribute-groups-list.component'
            ).then(m => m.AttributeGroupsListComponent),
          canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
        },
        {
          path: `create` as const,
          loadComponent: () =>
            import(
              './page-attribute/page-attribute-groups/attribute-group-create/attribute-group-create.component'
            ).then(m => m.AttributeGroupCreateComponent),
          canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
        },
        {
          path: `:attributeGroupId` as const,
          loadComponent: () =>
            import(
              './page-attribute/page-attribute-groups/attribute-groups-edit/attribute-groups-edit.component'
            ).then(m => m.AttributeGroupsEditComponent),
          canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
        },
      ],
    },
    {
      path: `kachel-groups` as const,
      loadComponent: () =>
        import(
          './page-attribute/page-attribute-kachel-group/page-attribute-kachel-group.component'
        ).then(m => m.PageAttributeKachelGroupComponent),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
      children: [
        {
          path: `` as const,
          loadComponent: () =>
            import(
              './page-attribute/page-attribute-kachel-group/attribute-kachel-group-list/attribute-kachel-group-list.component'
            ).then(m => m.AttributeKachelGroupListComponent),
          canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
        },
        {
          path: `create` as const,
          loadComponent: () =>
            import(
              './page-attribute/page-attribute-kachel-group/attribute-kachel-group-create/attribute-kachel-group-create.component'
            ).then(m => m.AttributeKachelGroupCreateComponent),
          canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
        },
        {
          path: `:attributeKachelGroupId` as const,
          loadComponent: () =>
            import(
              './page-attribute/page-attribute-kachel-group/attribute-kachel-group-edit/attribute-kachel-group-edit.component'
            ).then(m => m.AttributeKachelGroupEditComponent),
          canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
        },
      ],
    },
    {
      path: ':attributeId' as const,
      loadComponent: () =>
        import('./page-attribute/attribute-edit/attribute-edit.component').then(
          m => m.AttributeEditComponent
        ),
      canActivate: [AuthGuard],
    },
    {
      path: ':attributeId/tariffs' as const,
      loadComponent: () =>
        import(
          './page-attribute/attribute-list/tariffs-for-attribute-list/page-edit-attribute-for-tariffs/page-edit-attribute-for-tariffs.component'
        ).then(m => m.PageEditAttributeForTariffsComponent),
      canActivate: [AuthGuard],
    },
  ],
} satisfies Route;

const tariffContainersList = {
  path: '' as const,
  loadComponent: () =>
    import(
      './page-tariffs/page-tarif-containers/tarif-containers-list/tarif-containers-list.component'
    ).then(m => m.TarifContainersListComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
} satisfies Route;

const tariffContainerEdit = {
  path: ':tarifContainerId' as const,
  loadComponent: () =>
    import(
      './page-tariffs/page-tarif-containers/tarif-container-edit/tarif-container-edit.component'
    ).then(m => m.TarifContainerEditComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
} satisfies Route;

const tarifContainerAdd = {
  path: 'add' as const,
  loadComponent: () =>
    import(
      './page-tariffs/page-tarif-containers/tarif-container-add/tarif-container-add.component'
    ).then(m => m.TarifContainerAddComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
} satisfies Route;

const tariffs = {
  path: 'tariffs' as const,
  loadComponent: () =>
    import('./page-tariffs/page-tariffs.component').then(m => m.PageTariffsComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
  children: [
    {
      path: '' as const,
      loadComponent: () =>
        import('./page-tariffs/tarifs-list/tarifs-list.component').then(m => m.TarifsListComponent),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'add' as const,
      loadComponent: () =>
        import('./page-tariffs/tarif-add/tarif-add.component').then(m => m.TarifAddComponent),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'edit' as const,
      loadComponent: () =>
        import('./page-tariffs/tarif-edit/tarif-edit.component').then(m => m.TarifEditComponent),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'containers' as const,
      loadComponent: () =>
        import('./page-tariffs/page-tarif-containers/page-tarif-containers.component').then(
          m => m.PageTarifContainersComponent
        ),
      children: [tariffContainersList, tarifContainerAdd, tariffContainerEdit],
      canActivate: [AuthGuard],
    },
    {
      path: ':tarifId' as const,
      loadComponent: () =>
        import('./page-tariffs/tarif-edit/tarif-edit.component').then(m => m.TarifEditComponent),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
  ],
} satisfies Route;

const insurers = {
  path: 'insurers' as const,
  loadComponent: () =>
    import('./page-insurers/page-insurers.component').then(m => m.PageInsurersComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
  children: [
    {
      path: '' as const,
      loadComponent: () =>
        import('./page-insurers/insurers-list/insurers-list.component').then(
          m => m.InsurersListComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'add' as const,
      loadComponent: () =>
        import('./page-insurers/insurer-create-rx/insurer-create-rx.component').then(
          m => m.InsurerCreateRxComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: ':insurerId' as const,
      loadComponent: () =>
        import('./page-insurers/insurer-edit/insurer-edit.component').then(
          m => m.InsurerEditComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
  ],
} satisfies Route;

const tarifbereiche = {
  path: 'tarifbereiche' as const,
  loadComponent: () =>
    import('./page-tarifbereich/page-tarifbereich.component').then(
      m => m.PageTarifbereichComponent
    ),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
  children: [
    {
      path: '' as const,
      loadComponent: () =>
        import('./page-tarifbereich/tarifbereich-list/tarifbereich-list.component').then(
          m => m.TarifbereichListComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'add' as const,
      loadComponent: () =>
        import('./page-tarifbereich/tarifbereich-add/tarifbereich-add.component').then(
          m => m.TarifbereichAddComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: ':tarifbereichId' as const,
      loadComponent: () =>
        import('./page-tarifbereich/tarifbereich-edit/tarifbereich-edit.component').then(
          m => m.TarifbereichEditComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
  ],
};

const berufelisten = {
  path: 'berufelisten' as const,
  loadComponent: () =>
    import('./page-berufeliste/page-berufeliste.component').then(m => m.PageBerufelisteComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
  children: [
    {
      path: '' as const,
      loadComponent: () =>
        import('./page-berufeliste/berufelisten/berufelisten.component').then(
          m => m.BerufelistenComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'add' as const,
      loadComponent: () =>
        import('./page-berufeliste/berufeliste-add/berufeliste-add.component').then(
          m => m.BerufelisteAddComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: ':berufelisteId' as const,
      loadComponent: () =>
        import('./page-berufeliste/berufeliste-edit/berufeliste-edit.component').then(
          m => m.BerufelisteEditComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'show/:berufelisteId' as const,
      loadComponent: () =>
        import('./page-berufeliste/berufeliste-show/berufeliste-show.component').then(
          m => m.BerufelisteShowComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
  ],
};

const fondslisten = {
  path: 'fondslisten' as const,
  loadComponent: () =>
    import('./page-fondsliste/page-fondsliste.component').then(m => m.PageFondslisteComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
  children: [
    {
      path: '' as const,
      loadComponent: () =>
        import('./page-fondsliste/fondsliste-list/fondsliste-list.component').then(
          m => m.FondslisteListComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'add' as const,
      loadComponent: () =>
        import('./page-fondsliste/fondsliste-add/fondsliste-add.component').then(
          m => m.FondslisteAddComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: ':fondslisteId' as const,
      loadComponent: () =>
        import('./page-fondsliste/fondsliste-edit/fondsliste-edit.component').then(
          m => m.FondslisteEditComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'edit/:fondslisteId' as const,
      loadComponent: () =>
        import('./page-fondsliste/fonds-listedit/fonds-listedit.component').then(
          m => m.FondsListeditComponent
        ),
    },
  ],
};

const konstanten = {
  path: 'konstanten' as const,
  loadComponent: () =>
    import('./page-konstante/page-konstante.component').then(m => m.PageKonstanteComponent),
  canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
  children: [
    {
      path: '' as const,
      loadComponent: () =>
        import('./page-konstante/konstante-list/konstante-list.component').then(
          m => m.KonstanteListComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: 'add' as const,
      loadComponent: () =>
        import('./page-konstante/konstante-add/konstante-add.component').then(
          m => m.KonstanteAddComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
    {
      path: ':konstanteId' as const,
      loadComponent: () =>
        import('./page-konstante/konstante-edit/konstante-edit.component').then(
          m => m.KonstanteEditComponent
        ),
      canActivate: [AuthGuard, sparteGuardWithRedirect(dashboard)],
    },
  ],
};

const page404 = {
  path: '404' as const,
  loadComponent: () => import('./page-404/page-404.component').then(m => m.Page404Component),
};

const unknownPage = {
  path: '**' as const,
  loadComponent: page404.loadComponent,
};

/**
 * Angular Routes
 */
export const ROUTESLIST = [
  dashboard,
  attributes,
  tariffs,
  insurers,
  tarifbereiche,
  berufelisten,
  fondslisten,
  konstanten,
  page404,
  unknownPage,
] satisfies Route[];
export type RouteList = typeof ROUTESLIST;
