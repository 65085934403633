<ng-template #loading>
  <mat-spinner [diameter]="20" />
</ng-template>

<ng-template #onlyForm>
  <ng-content />
</ng-template>

@if (enabled) {
  @if ({ edit: editStatus$ | async, changed: valueChanged$ | async }; as state) {
    <div
      [class.padding]="padding"
      [class.pending]="loading$ | async"
      [class.show-actions-on-hover]="enableReadonlyMode"
    >
      <form [formGroup]="fg" (submit)="doSave()" novalidate>
        @if (readChild) {
          @if (state.edit === false) {
            <ng-container *ngTemplateOutlet="readChild" />
          } @else {
            <ng-container *ngTemplateOutlet="onlyForm" />
          }
        } @else {
          <ng-container *ngTemplateOutlet="onlyForm" />
        }
        <div
          [class.force-show-actions]="state.changed"
          class="actions flex justify-end items-center"
        >
          <ng-template #actions>
            @if (!state.edit) {
              <button type="button" (click)="readonly()">
                <mat-icon svgIcon="cp-edited" />
              </button>
            }
            @if (state.edit) {
              <button type="button" (click)="cancel()">
                <mat-icon svgIcon="cp-close" />
              </button>
            }
            @if (state.edit) {
              <button type="button" (click)="doSave()">
                <mat-icon svgIcon="cp-check" />
              </button>
            }
          </ng-template>
          @if (loading$ | async) {
            <ng-container *ngTemplateOutlet="loading" />
          } @else {
            @if (!state.edit) {
              <button type="button" (click)="readonly()">
                <mat-icon svgIcon="cp-edited" />
              </button>
            }
            @if (state.edit) {
              <button type="button" (click)="cancel()">
                <mat-icon svgIcon="cp-close" />
              </button>
            }
            @if (state.edit) {
              <button type="button" (click)="doSave()">
                <mat-icon svgIcon="cp-check" />
              </button>
            }
          }
        </div>
      </form>
    </div>
  }
} @else {
  <ng-container *ngTemplateOutlet="onlyForm" />
}
