@for (alert of data$ | async; track trackById($index, alert)) {
  <div
    class="flex justify-between py-4 my-2 min-w-80 w-fit px-6 gap-3 text-white rounded"
    [ngClass]="backgroundColor[alert.type]"
  >
    <div class="flex gap-3">
      @if (alert.type !== 'success') {
        <mat-icon svgIcon="cp-info" class="stroke-none" />
      }
      @if (alert.type === 'success') {
        <mat-icon svgIcon="cp-check" class="stroke-white" />
      }
      <span>{{ alert.message }}</span>
    </div>
    <mat-icon
      (click)="snackbarService.close(alert.id, alert.position)"
      class="stroke-white cursor-pointer"
      svgIcon="cp-close"
    />
  </div>
}
