import { AsyncPipe } from '@angular/common';
import type { TrackByFunction } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import type { Observable } from 'rxjs';
import { BehaviorSubject, ReplaySubject, combineLatest, map, startWith } from 'rxjs';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';

interface ChipState {
  label: string;
  value: string;
  selected: boolean;
  disabled: boolean;
}

@Component({
  selector: 'ui-chips-toggle',
  standalone: true,
  imports: [AsyncPipe, MatChipsModule],
  templateUrl: './chips-toggle.component.html',
  styleUrls: ['./chips-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsToggleComponent),
      multi: true,
    },
  ],
})
export class ChipsToggleComponent extends ControlValueAccessorDirective<string[]> {
  trackByValue: TrackByFunction<ChipState> = (_, c) => c.value;

  values$ = new BehaviorSubject<string[]>([]);
  options$ = new ReplaySubject<ChipsToggleOption[]>(1);
  state$: Observable<ChipState[]>;

  @Input()
  set options(options: ChipsToggleOption[] | undefined | null) {
    this.options$.next(options || []);
  }

  constructor() {
    super();

    const options$: Observable<ChipsToggleOption[]> = this.options$.pipe(startWith([]));
    const values$: Observable<string[]> = this.values$.pipe(startWith([]));

    this.state$ = combineLatest([options$, values$, this.disabled$]).pipe(
      map(([options, values, disabled]) => {
        return options.map(o => ({
          ...o,
          disabled,
          selected: Boolean(values.includes(o.value)),
        }));
      })
    );
  }

  override writeValue(value: string[]): void {
    super.writeValue(value);
    this.values$.next(value);
  }

  toggle(value: string): void {
    const values = this.values$.value;
    if (values.includes(value)) {
      this.values$.next(values.filter(v => v !== value));
    } else {
      this.values$.next([...values, value]);
    }
    this.onTouched();
    this.onChange(this.values$.value);
  }
}

export interface ChipsToggleOption {
  value: string;
  label: string;
}
