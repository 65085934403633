@if (state$ | async; as state) {
  <div class="dropbox" #dropbox [class.dragging]="state.dragging" [class.disabled]="state.disabled">
    <input
      type="file"
      #fileInput
      id="fileInput"
      (change)="onFileInputChange($event)"
      [disabled]="state.disabled"
      [accept]="state.mediaTypes"
    />
    <ng-content />
    @if (state.value) {
      <div class="flex justify-center items-center">
        <span>{{ state.value.name }} ({{ state.value.size | toUnit: 'MB' }})</span>
        <button
          [disabled]="state.disabled"
          (click)="removeFile()"
          mat-icon-button
          color="warn"
          matTooltip="ausgewählte Datei entfernen"
        >
          <mat-icon svgIcon="cp-close-filled" />
        </button>
        @if (state.mediaTypes?.includes(fileType.svg)) {
          @if (value$ | async | map: getImagePreview | async; as data) {
            <div class="image-preview">
              <img class="w-10 h-auto" [src]="'data:' + fileType.svg + ';base64,' + data" />
            </div>
          }
        }
      </div>
    }
    @if (!state.value) {
      <button mat-flat-button class="btn-secondary-sm" (click)="fileInput.click()">
        {{ label }}
      </button>
    }
    @if (maxFileSizeMb) {
      <small class="text-cp-primary-60">max. {{ maxFileSizeMb }} MB Dateigröße</small>
    }
  </div>
}

@if (uploadInfo$ | async; as uploadInfo) {
  <div class="flex flex-col gap-2 mt-4">
    @if (uploadInfo.status) {
      <div class="flex flex-col gap-2">
        <small
          [ngClass]="uploadInfo.status === 'error' ? 'text-cp-error-100' : 'text-cp-primary-60'"
        >
          {{
            uploadInfo.status === 'error'
              ? 'Hochladen fehlgeschlagen'
              : uploadInfo.progress === 100
                ? 'Dokument ist hochgeladen'
                : 'Dokument wird hochgeladen..'
          }}</small
        >
        <div class="flex items-center gap-2">
          <mat-progress-bar
            [color]="uploadInfo.status === 'error' ? 'warn' : 'accent'"
            [value]="uploadInfo.progress"
          />
          @if (uploadInfo.status) {
            @if (uploadInfo.status === 'progress') {
              <button class="min-rounded-button" (click)="cancelUpload.next(true)" mat-icon-button>
                <mat-icon svgIcon="cp-close" />
              </button>
            }
            @if (uploadInfo.status === 'error') {
              <button class="min-rounded-button" (click)="resetUpload.next(true)" mat-icon-button>
                <mat-icon svgIcon="cp-reset" />
              </button>
            }
          }
        </div>
      </div>
    }
    @if (uploadInfo.uploadedDocuments?.length) {
      <div class="flex flex-col gap-2">
        <small class="text-cp-primary-60">Hochgeladene Dokumente</small>
        @for (document of uploadInfo.uploadedDocuments; track document) {
          <div class="flex justify-between">
            <strong>{{ document.name }}</strong>
            <button
              class="min-rounded-button"
              (click)="deleteDocument.next(document.id)"
              mat-icon-button
            >
              <mat-icon svgIcon="cp-close" />
            </button>
          </div>
        }
      </div>
    }
  </div>
  <ui-divider />
}
