<ui-header class="mb-10" [links]="links$ | async">
  <ng-container ngProjectAs="'[logo]'">
    @if (headerLogo$ | async; as headerLogo) {
      <div class="flex items-center justify-center gap-2">
        <a [routerLink]="''">
          <img class="h-[40px]" [src]="'data:image/svg+xml;base64,' + headerLogo.logo" [alt]="headerLogo.produkt" />
        </a>
        <span class="ml-4">{{ headerLogo.sparte }}</span>
      </div>
    } @else {
      <div class="flex items-center justify-center">
        <a [routerLink]="''">
          <img class="w-[126px]" src="/assets/logo.svg" alt="Company Logo" />
        </a>
      </div>
    }
  </ng-container>

  <ng-container ngProjectAs="[avatar]">
    @if (keycloakService.isLoggedIn()) {
      <comparit-avatar-user />
    }
  </ng-container>
</ui-header>

<div class="page-container">
  <router-outlet />
</div>
