import type { ApplicationConfig } from '@angular/core';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideServiceWorker } from '@angular/service-worker';
import {
  CustomDateAdapter,
  CustomIconsService,
  ErrorIntercept,
  GlobalErrorHandler,
  GlobalErrorStateMatcher,
  IS_DEVELOP_TOKEN,
  KeycloakAuthService,
  MAT_FORM_FIELD_OPTIONS,
  PromptUpdateService,
} from '@comparit/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { environment } from '../environments/environment';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ApiModule, Configuration } from '../ti-interfaces';

export function initializeKeycloak(keycloakService: KeycloakService) {
  return () =>
    keycloakService.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: true,
      },
      loadUserProfileAtStartUp: true,
    });
}

export const customDateAdapter = { provide: DateAdapter, useClass: CustomDateAdapter };

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (updateService: PromptUpdateService) => {
        if (!environment.enableServiceWorker) return () => false;
        return updateService.checkForUpdates();
      },
      multi: true,
      deps: [PromptUpdateService],
    },
    { provide: IS_DEVELOP_TOKEN, useValue: !environment.production },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, KeycloakAuthService],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    CustomIconsService,
    { provide: ErrorStateMatcher, useClass: GlobalErrorStateMatcher },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MAT_FORM_FIELD_OPTIONS,
    },
    importProvidersFrom([
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      KeycloakAngularModule,
      MatSnackBarModule,
      ApiModule.forRoot(() => new Configuration({ basePath: environment.apiUrl })),
    ]),
    provideRouter(appRoutes),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true,
    },
  ],
};
