<nav mat-tab-nav-bar [mat-stretch-tabs]="false" [tabPanel]="tabPanel">
  @for (link of links; track trackById($index)) {
    <a
      mat-tab-link
      [routerLink]="link.url ? link.url : ''"
      [routerLinkActiveOptions]="routerLinkActiveOptions"
      routerLinkActive="active"
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ link.title }}
    </a>
  }
</nav>

<mat-tab-nav-panel #tabPanel />
