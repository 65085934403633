/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule, DOCUMENT } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import type { ControlValueAccessor, FormControl, ValidatorFn } from '@angular/forms';
import {
  FormBuilder,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DomHandler } from 'primeng/dom';
import { InputErrorsComponent } from '../input-errors/input-errors.component';
import { controlMarkAsTouched } from '../../misc/formMarkAllAsTouched';

/**
 * Test for later....
 */
export declare type Nullable<T = void> = T | null | undefined;

type InputNumberInputEvent = {
  originalEvent: Event;
  value: number;
  formattedValue: string;
};

export const INPUTNUMBER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumberComponent),
  multi: true,
};
/**
 * InputNumber is an input component to provide numerical input.
 * Attention COPY PASTE Component
 */
@Component({
  selector: 'ui-input-number',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    InputErrorsComponent,
  ],
  template: `
    <mat-form-field>
      <mat-label>{{ label }}</mat-label>
      <input
        #input
        matInput
        [formControl]="control"
        [attr.id]="inputId"
        role="spinbutton"
        [ngStyle]="inputStyle"
        [class]="inputStyleClass"
        [attr.aria-valuemin]="min"
        [attr.aria-valuemax]="max"
        [attr.aria-valuenow]="value"
        [readonly]="readonly"
        [attr.placeholder]="placeholder"
        [attr.title]="title"
        [attr.size]="size"
        [attr.name]="name"
        [attr.autocomplete]="autocomplete"
        [attr.maxlength]="maxlength"
        [attr.tabindex]="tabindex"
        [attr.min]="min"
        [attr.max]="max"
        inputmode="decimal"
        (input)="onUserInput($event)"
        (keydown)="onInputKeyDown($event)"
        (keypress)="onInputKeyPress($event)"
        (paste)="onPaste($event)"
        (click)="onInputClick()"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
      />
      @if (euroSign) {
        <span matTextSuffix>€</span>
      }
      <span matTextSuffix><ng-content /></span>
    </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [INPUTNUMBER_VALUE_ACCESSOR],
})
export class InputNumberComponent<T> implements OnInit, OnChanges, ControlValueAccessor {
  @Input() label = '';

  /**
   * Displays spinner buttons.
   * @group Props
   */
  @Input() showButtons = false;
  /**
   * Whether to format the value.
   * @group Props
   */
  @Input() format = true;
  /**
   * Layout of the buttons, valid values are "stacked" (default), "horizontal" and "vertical".
   * @group Props
   */
  @Input() buttonLayout = 'stacked';
  /**
   * Identifier of the focus input to match a label defined for the component.
   * @group Props
   */
  @Input() inputId: string | undefined;
  /**
   * Style class of the component.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Inline style of the component.
   * @group Props
   */
  @Input() style: { [klass: string]: any } | null | undefined;
  /**
   * Advisory information to display on input.
   * @group Props
   */
  @Input() placeholder: string | undefined;
  /**
   * Size of the input field.
   * @group Props
   */
  @Input() size: number | undefined;
  /**
   * Maximum number of character allows in the input field.
   * @group Props
   */
  @Input() maxlength: number | undefined;
  /**
   * Specifies tab order of the element.
   * @group Props
   */
  @Input() tabindex: number | undefined;
  /**
   * Title text of the input text.
   * @group Props
   */
  @Input() title: string | undefined;
  /**
   * Specifies one or more IDs in the DOM that labels the input field.
   * @group Props
   */
  @Input() ariaLabelledBy: string | undefined;
  /**
   * Used to define a string that labels the input element.
   * @group Props
   */
  @Input() ariaLabel: string | undefined;
  /**
   * Used to indicate that user input is required on an element before a form can be submitted.
   * @group Props
   */
  @Input() ariaRequired: boolean | undefined;
  /**
   * Name of the input field.
   * @group Props
   */
  @Input() name: string | undefined;
  /**
   * Indicates that whether the input field is required.
   * @group Props
   */
  @Input() required: boolean | undefined;
  /**
   * Used to define a string that autocomplete attribute the current element.
   * @group Props
   */
  @Input() autocomplete: string | undefined;
  /**
   * Mininum boundary value.
   * @group Props
   */
  @Input() min: number | undefined;
  /**
   * Maximum boundary value.
   * @group Props
   */
  @Input() max: number | undefined;
  /**
   * Style class of the increment button.
   * @group Props
   */
  @Input() incrementButtonClass: string | undefined;
  /**
   * Style class of the decrement button.
   * @group Props
   */
  @Input() decrementButtonClass: string | undefined;
  /**
   * Style class of the increment button.
   * @group Props
   */
  @Input() incrementButtonIcon: string | undefined;
  /**
   * Style class of the decrement button.
   * @group Props
   */
  @Input() decrementButtonIcon: string | undefined;
  /**
   * When present, it specifies that an input field is read-only.
   * @group Props
   */
  @Input() readonly = false;
  /**
   * Step factor to increment/decrement the value.
   * @group Props
   */
  @Input() step = 1;
  /**
   * Determines whether the input field is empty.
   * @group Props
   */
  @Input() allowEmpty = true;
  /**
   * Locale to be used in formatting.
   * @group Props
   */
  @Input() locale: string | undefined;
  /**
   * The locale matching algorithm to use. Possible values are "lookup" and "best fit"; the default is "best fit". See Locale Negotiation for details.
   * @group Props
   */
  @Input() localeMatcher: string | undefined;
  /**
   * Defines the behavior of the component, valid values are "decimal" and "currency".
   * @group Props
   */
  @Input() mode = 'decimal';
  /**
   * The currency to use in currency formatting. Possible values are the ISO 4217 currency codes, such as "USD" for the US dollar, "EUR" for the euro, or "CNY" for the Chinese RMB. There is no default value; if the style is "currency", the currency property must be provided.
   * @group Props
   */
  @Input() currency: string | undefined;
  /**
   * How to display the currency in currency formatting. Possible values are "symbol" to use a localized currency symbol such as €, ü"code" to use the ISO currency code, "name" to use a localized currency name such as "dollar"; the default is "symbol".
   * @group Props
   */
  @Input() currencyDisplay: string | undefined;
  /**
   * Whether to use grouping separators, such as thousands separators or thousand/lakh/crore separators.
   * @group Props
   */
  @Input() useGrouping = true;
  /**
   * The minimum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number and percent formatting is 0; the default for currency formatting is the number of minor unit digits provided by the ISO 4217 currency code list (2 if the list doesn't provide that information).
   * @group Props
   */
  @Input() minFractionDigits: number | undefined;
  /**
   * The maximum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number formatting is the larger of minimumFractionDigits and 3; the default for currency formatting is the larger of minimumFractionDigits and the number of minor unit digits provided by the ISO 4217 currency code list (2 if the list doesn't provide that information).
   * @group Props
   */
  @Input() maxFractionDigits: number | undefined;
  /**
   * Text to display before the value.
   * @group Props
   */
  @Input() prefix: string | undefined;
  /**
   * Text to display after the value.
   * @group Props
   */
  @Input() suffix: string | undefined;
  /**
   * Add euro sign
   * @group Props
   */
  @Input() euroSign: boolean | undefined;
  /**
   * Inline style of the input field.
   * @group Props
   */
  @Input() inputStyle: any;
  /**
   * Style class of the input field.
   * @group Props
   */
  @Input() inputStyleClass = '';
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  @Input() showClear = false;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  @Input() get disabled(): boolean | undefined {
    return this._disabled;
  }
  set disabled(disabled: boolean | undefined) {
    if (disabled) this.focused = false;

    this._disabled = disabled;

    if (this.timer) this.clearTimer();
  }
  /**
   * Callback to invoke on input.
   * @param {InputNumberInputEvent} event - Custom input event.
   * @group Emits
   */
  @Output() OnInput: EventEmitter<InputNumberInputEvent> =
    new EventEmitter<InputNumberInputEvent>();
  /**
   * Callback to invoke when the component receives focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() OnFocus: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke when the component loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() OnBlur: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke on input key press.
   * @param {KeyboardEvent} event - Keyboard event.
   * @group Emits
   */
  @Output() OnKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  /**
   * Callback to invoke when clear token is clicked.
   * @group Emits
   */
  @Output() OnClear: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('input') input!: ElementRef;

  clearIconTemplate: Nullable<TemplateRef<any>>;

  incrementButtonIconTemplate: Nullable<TemplateRef<any>>;

  decrementButtonIconTemplate: Nullable<TemplateRef<any>>;

  value: Nullable<number>;

  onModelChange = (value: number | null) => {};

  onModelTouched = () => {};

  focused: Nullable<boolean>;

  initialized: Nullable<boolean>;

  groupChar = '';

  prefixChar = '';

  suffixChar = '';

  isSpecialChar: Nullable<boolean>;

  timer: any;

  lastValue: Nullable<string>;

  _numeral: any;

  numberFormat: any;

  _decimal: any;

  _group: any;

  _minusSign: any;

  _currency: Nullable<RegExp | string>;

  _prefix!: RegExp;

  _suffix!: RegExp;

  _index: number | any;

  _disabled: boolean | undefined;

  control = new FormBuilder().nonNullable.control('', [Validators.required]);

  private _ngControl!: FormControl<T>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cd: ChangeDetectorRef,
    private readonly injector: Injector
  ) {}

  updateControlValue = () => {
    this.control.setValue(this.formattedValue());
    controlMarkAsTouched(this.control);
  };

  ngOnChanges(simpleChange: SimpleChanges) {
    const props = [
      'locale',
      'localeMatcher',
      'mode',
      'currency',
      'currencyDisplay',
      'useGrouping',
      'minFractionDigits',
      'maxFractionDigits',
      'prefix',
      'suffix',
    ];
    if (props.some(p => !!simpleChange[p])) {
      this.updateConstructParser();
    }
  }

  /**
   * Set this method only on init!
   */
  private _getControl(): FormControl<T> {
    try {
      const formControl = this.injector.get(NgControl);
      if (formControl instanceof FormControlName) {
        // !Do not remove this code...
        // const grouname = this.injector.get(FormGroupName);
        // if (grouname) {
        //   return this.injector
        //     .get(FormGroupDirective)
        //     .getFormGroup(grouname)
        //     .get(formControl.name as string) as FormControl<T>;
        // }
        return this.injector.get(FormGroupDirective).getControl(formControl);
      } else if (formControl instanceof NgModel) {
        return this.injector.get(NgModel).control;
      } else {
        return formControl.control as FormControl;
      }
    } catch (err) {
      if (this._ngControl) {
        // recycing the last in-component ctrl because we dont have to create an new one
        return this._ngControl;
      }
      return new FormBuilder().nonNullable.control(this.value as unknown as T);
    }
  }

  ngOnInit() {
    this._ngControl = this._getControl();
    const parentValidators = (<any>this._ngControl)._rawValidators as ValidatorFn;
    this.control.setValidators(parentValidators);
    this.constructParser();
    this.initialized = true;
  }

  getOptions() {
    return {
      localeMatcher: this.localeMatcher,
      style: this.mode,
      currency: this.currency,
      currencyDisplay: this.currencyDisplay,
      useGrouping: this.useGrouping,
      minimumFractionDigits: this.minFractionDigits,
      maximumFractionDigits: this.maxFractionDigits,
    };
  }

  constructParser() {
    this.numberFormat = new Intl.NumberFormat(this.locale, this.getOptions());
    const numerals = [
      ...new Intl.NumberFormat(this.locale, { useGrouping: false }).format(9876543210),
    ].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this._group = this.getGroupingExpression();
    this._minusSign = this.getMinusSignExpression();
    this._currency = this.getCurrencyExpression();
    this._decimal = this.getDecimalExpression();
    this._suffix = this.getSuffixExpression();
    this._prefix = this.getPrefixExpression();
    this._index = (d: any) => index.get(d);
  }

  updateConstructParser() {
    if (this.initialized) {
      this.constructParser();
    }
  }

  escapeRegExp(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  getDecimalExpression(): RegExp {
    const formatter = new Intl.NumberFormat(this.locale, {
      ...this.getOptions(),
      useGrouping: false,
    });
    return new RegExp(
      `[${formatter
        .format(1.1)
        .replace(this._currency || '', '')
        .trim()
        .replace(this._numeral, '')}]`,
      'g'
    );
  }

  getGroupingExpression(): RegExp {
    const formatter = new Intl.NumberFormat(this.locale, { useGrouping: true });
    this.groupChar = formatter.format(1000000).trim().replace(this._numeral, '').charAt(0);
    return new RegExp(`[${this.groupChar}]`, 'g');
  }

  getMinusSignExpression(): RegExp {
    const formatter = new Intl.NumberFormat(this.locale, { useGrouping: false });
    return new RegExp(`[${formatter.format(-1).trim().replace(this._numeral, '')}]`, 'g');
  }

  getCurrencyExpression(): RegExp {
    if (this.currency) {
      const formatter = new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency,
        currencyDisplay: this.currencyDisplay,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return new RegExp(
        `[${formatter
          .format(1)
          .replace(/\s/g, '')
          .replace(this._numeral, '')
          .replace(this._group, '')}]`,
        'g'
      );
    }

    return new RegExp(`[]`, 'g');
  }

  getPrefixExpression(): RegExp {
    if (this.prefix) {
      this.prefixChar = this.prefix;
    } else {
      const formatter = new Intl.NumberFormat(this.locale, {
        style: this.mode,
        currency: this.currency,
        currencyDisplay: this.currencyDisplay,
      });
      this.prefixChar = formatter.format(1).split('1')[0];
    }

    return new RegExp(`${this.escapeRegExp(this.prefixChar || '')}`, 'g');
  }

  getSuffixExpression(): RegExp {
    if (this.suffix) {
      this.suffixChar = this.suffix;
    } else {
      const formatter = new Intl.NumberFormat(this.locale, {
        style: this.mode,
        currency: this.currency,
        currencyDisplay: this.currencyDisplay,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      this.suffixChar = formatter.format(1).split('1')[1];
    }

    return new RegExp(`${this.escapeRegExp(this.suffixChar || '')}`, 'g');
  }

  formatValue(value: any) {
    if (value != null) {
      if (value === '-') {
        // Minus sign
        return value;
      }

      if (this.format) {
        const formatter = new Intl.NumberFormat(this.locale, this.getOptions());
        let formattedValue = formatter.format(value);
        if (this.prefix) {
          formattedValue = this.prefix + formattedValue;
        }

        if (this.suffix) {
          formattedValue = formattedValue + this.suffix;
        }

        return formattedValue;
      }

      return value.toString();
    }

    return '';
  }

  parseValue(text: any) {
    const filteredText = text
      .replace(this._suffix, '')
      .replace(this._prefix, '')
      .trim()
      .replace(/\s/g, '')
      .replace(this._currency as RegExp, '')
      .replace(this._group, '')
      .replace(this._minusSign, '-')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index);

    if (filteredText) {
      if (filteredText === '-')
        // Minus sign
        return filteredText;

      const parsedValue = +filteredText;
      return isNaN(parsedValue) ? null : parsedValue;
    }

    return null;
  }

  repeat(event: Event, interval: number | null, dir: number) {
    if (this.readonly) {
      return;
    }

    const i = interval || 500;

    this.clearTimer();
    this.timer = setTimeout(() => {
      this.repeat(event, 40, dir);
    }, i);

    this.spin(event, dir);
  }

  spin(event: Event, dir: number) {
    const step = this.step * dir;
    const currentValue = this.parseValue(this.input?.nativeElement.value) || 0;
    const newValue = this.validateValue((currentValue as number) + step);
    if (this.maxlength && this.maxlength < this.formatValue(newValue).length) {
      return;
    }
    this.updateInput(newValue, null, 'spin', null);
    this.updateModel(event, newValue);

    this.handleOnInput(event, currentValue, newValue);
  }

  clear() {
    this.value = null;
    this.onModelChange(this.value);
    this.OnClear.emit();
  }

  onUpButtonMouseDown(event: MouseEvent) {
    if (event.button === 2) {
      this.clearTimer();
      return;
    }

    if (!this.disabled) {
      this.input?.nativeElement.focus();
      this.repeat(event, null, 1);
      event.preventDefault();
    }
  }

  onUpButtonMouseUp() {
    if (!this.disabled) {
      this.clearTimer();
    }
  }

  onUpButtonMouseLeave() {
    if (!this.disabled) {
      this.clearTimer();
    }
  }

  onUpButtonKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 32 || event.keyCode === 13) {
      this.repeat(event, null, 1);
    }
  }

  onUpButtonKeyUp() {
    if (!this.disabled) {
      this.clearTimer();
    }
  }

  onDownButtonMouseDown(event: MouseEvent) {
    if (event.button === 2) {
      this.clearTimer();
      return;
    }
    if (!this.disabled) {
      this.input?.nativeElement.focus();
      this.repeat(event, null, -1);
      event.preventDefault();
    }
  }

  onDownButtonMouseUp() {
    if (!this.disabled) {
      this.clearTimer();
    }
  }

  onDownButtonMouseLeave() {
    if (!this.disabled) {
      this.clearTimer();
    }
  }

  onDownButtonKeyUp() {
    if (!this.disabled) {
      this.clearTimer();
    }
  }

  onDownButtonKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 32 || event.keyCode === 13) {
      this.repeat(event, null, -1);
    }
  }

  onUserInput(event: Event) {
    if (this.readonly) {
      return;
    }

    if (this.isSpecialChar) {
      (event.target as HTMLInputElement).value = this.lastValue || '';
    }
    this.isSpecialChar = false;
  }

  onInputKeyDown(event: KeyboardEvent) {
    if (this.readonly) {
      return;
    }

    this.lastValue = (event.target as HTMLInputElement).value;
    if (event.shiftKey || event.altKey) {
      this.isSpecialChar = true;
      return;
    }

    const selectionStart = (event.target as HTMLInputElement).selectionStart || 0;
    const selectionEnd = (event.target as HTMLInputElement).selectionEnd || 0;
    const inputValue = (event.target as HTMLInputElement).value;
    let newValueStr = null;

    if (event.altKey) {
      event.preventDefault();
    }

    switch (event.code) {
      case 'ArrowUp':
        this.spin(event, 1);
        event.preventDefault();
        break;

      case 'ArrowDown':
        this.spin(event, -1);
        event.preventDefault();
        break;

      case 'ArrowLeft':
        if (!this.isNumeralChar(inputValue.charAt(selectionStart - 1))) {
          event.preventDefault();
        }
        break;

      case 'ArrowRight':
        if (!this.isNumeralChar(inputValue.charAt(selectionStart))) {
          event.preventDefault();
        }
        break;

      case 'Tab':
      case 'Enter':
        newValueStr = this.validateValue(this.parseValue(this.input.nativeElement.value));
        this.input.nativeElement.value = this.formatValue(newValueStr);
        this.input.nativeElement.setAttribute('aria-valuenow', newValueStr);
        this.updateModel(event, newValueStr);
        break;

      case 'Backspace': {
        event.preventDefault();

        if (selectionStart === selectionEnd) {
          const deleteChar = inputValue.charAt(selectionStart - 1);
          const { decimalCharIndex, decimalCharIndexWithoutPrefix } =
            this.getDecimalCharIndexes(inputValue);

          if (this.isNumeralChar(deleteChar)) {
            const decimalLength = this.getDecimalLength(inputValue);

            if (this._group.test(deleteChar)) {
              this._group.lastIndex = 0;
              newValueStr =
                inputValue.slice(0, selectionStart - 2) + inputValue.slice(selectionStart - 1);
            } else if (this._decimal.test(deleteChar)) {
              this._decimal.lastIndex = 0;

              if (decimalLength) {
                this.input?.nativeElement.setSelectionRange(selectionStart - 1, selectionStart - 1);
              } else {
                newValueStr =
                  inputValue.slice(0, selectionStart - 1) + inputValue.slice(selectionStart);
              }
            } else if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
              const insertedText =
                this.isDecimalMode() && (this.minFractionDigits || 0) < decimalLength ? '' : '0';
              newValueStr =
                inputValue.slice(0, selectionStart - 1) +
                insertedText +
                inputValue.slice(selectionStart);
            } else if (decimalCharIndexWithoutPrefix === 1) {
              newValueStr =
                inputValue.slice(0, selectionStart - 1) + '0' + inputValue.slice(selectionStart);
              newValueStr = (this.parseValue(newValueStr) as number) > 0 ? newValueStr : '';
            } else {
              newValueStr =
                inputValue.slice(0, selectionStart - 1) + inputValue.slice(selectionStart);
            }
          }

          this.updateValue(event, newValueStr, null, 'delete-single');
        } else {
          newValueStr = this.deleteRange(inputValue, selectionStart, selectionEnd);
          this.updateValue(event, newValueStr, null, 'delete-range');
        }

        break;
      }

      case 'Delete':
        event.preventDefault();

        if (selectionStart === selectionEnd) {
          const deleteChar = inputValue.charAt(selectionStart);
          const { decimalCharIndex, decimalCharIndexWithoutPrefix } =
            this.getDecimalCharIndexes(inputValue);

          if (this.isNumeralChar(deleteChar)) {
            const decimalLength = this.getDecimalLength(inputValue);

            if (this._group.test(deleteChar)) {
              this._group.lastIndex = 0;
              newValueStr =
                inputValue.slice(0, selectionStart) + inputValue.slice(selectionStart + 2);
            } else if (this._decimal.test(deleteChar)) {
              this._decimal.lastIndex = 0;

              if (decimalLength) {
                this.input?.nativeElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
              } else {
                newValueStr =
                  inputValue.slice(0, selectionStart) + inputValue.slice(selectionStart + 1);
              }
            } else if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
              const insertedText =
                this.isDecimalMode() && (this.minFractionDigits || 0) < decimalLength ? '' : '0';
              newValueStr =
                inputValue.slice(0, selectionStart) +
                insertedText +
                inputValue.slice(selectionStart + 1);
            } else if (decimalCharIndexWithoutPrefix === 1) {
              newValueStr =
                inputValue.slice(0, selectionStart) + '0' + inputValue.slice(selectionStart + 1);
              newValueStr = (this.parseValue(newValueStr) as number) > 0 ? newValueStr : '';
            } else {
              newValueStr =
                inputValue.slice(0, selectionStart) + inputValue.slice(selectionStart + 1);
            }
          }

          this.updateValue(event, newValueStr as string, null, 'delete-back-single');
        } else {
          newValueStr = this.deleteRange(inputValue, selectionStart, selectionEnd);
          this.updateValue(event, newValueStr, null, 'delete-range');
        }
        break;

      case 'Home':
        if (this.min) {
          this.updateModel(event, this.min);
          event.preventDefault();
        }
        break;

      case 'End':
        if (this.max) {
          this.updateModel(event, this.max);
          event.preventDefault();
        }
        break;

      default:
        break;
    }

    this.OnKeyDown.emit(event);
  }

  onInputKeyPress(event: KeyboardEvent) {
    if (this.readonly) {
      return;
    }

    const code = event.which || event.keyCode;
    const char = String.fromCharCode(code);
    const isDecimalSign = this.isDecimalSign(char);
    const isMinusSign = this.isMinusSign(char);

    if (code != 13) {
      event.preventDefault();
    }

    const newValue = this.parseValue(this.input.nativeElement.value + char);
    const newValueStr = newValue != null ? newValue.toString() : '';
    if (this.maxlength && newValueStr.length > this.maxlength) {
      return;
    }

    if ((48 <= code && code <= 57) || isMinusSign || isDecimalSign) {
      this.insert(event, char, { isDecimalSign, isMinusSign });
    }
  }

  onPaste(event: ClipboardEvent) {
    if (!this.disabled && !this.readonly) {
      event.preventDefault();
      let data = (
        event.clipboardData || (this.document as any).defaultView['clipboardData']
      ).getData('Text');
      if (data) {
        if (this.maxlength) {
          data = data.toString().substring(0, this.maxlength);
        }

        const filteredData = this.parseValue(data);
        if (filteredData != null) {
          this.insert(event, filteredData.toString());
        }
      }
    }
  }

  allowMinusSign() {
    return this.min == null || this.min < 0;
  }

  isMinusSign(char: string) {
    if (this._minusSign.test(char) || char === '-') {
      this._minusSign.lastIndex = 0;
      return true;
    }

    return false;
  }

  isDecimalSign(char: string) {
    if (this._decimal.test(char)) {
      this._decimal.lastIndex = 0;
      return true;
    }

    return false;
  }

  isDecimalMode() {
    return this.mode === 'decimal';
  }

  getDecimalCharIndexes(val: string) {
    const decimalCharIndex = val.search(this._decimal);
    this._decimal.lastIndex = 0;

    const filteredVal = val
      .replace(this._prefix, '')
      .trim()
      .replace(/\s/g, '')
      .replace(this._currency as RegExp, '');
    const decimalCharIndexWithoutPrefix = filteredVal.search(this._decimal);
    this._decimal.lastIndex = 0;

    return { decimalCharIndex, decimalCharIndexWithoutPrefix };
  }

  getCharIndexes(val: string) {
    const decimalCharIndex = val.search(this._decimal);
    this._decimal.lastIndex = 0;
    const minusCharIndex = val.search(this._minusSign);
    this._minusSign.lastIndex = 0;
    const suffixCharIndex = val.search(this._suffix);
    this._suffix.lastIndex = 0;
    const currencyCharIndex = val.search(this._currency as RegExp);
    (this._currency as RegExp).lastIndex = 0;

    return { decimalCharIndex, minusCharIndex, suffixCharIndex, currencyCharIndex };
  }

  insert(event: Event, text: string, sign = { isDecimalSign: false, isMinusSign: false }) {
    const minusCharIndexOnText = text.search(this._minusSign);
    this._minusSign.lastIndex = 0;
    if (!this.allowMinusSign() && minusCharIndexOnText !== -1) {
      return;
    }

    const selectionStart = this.input?.nativeElement.selectionStart;
    const selectionEnd = this.input?.nativeElement.selectionEnd;
    const inputValue = this.input?.nativeElement.value.trim();
    const { decimalCharIndex, minusCharIndex, suffixCharIndex, currencyCharIndex } =
      this.getCharIndexes(inputValue);
    let newValueStr;

    if (sign.isMinusSign) {
      if (selectionStart === 0) {
        newValueStr = inputValue;
        if (minusCharIndex === -1 || selectionEnd !== 0) {
          newValueStr = this.insertText(inputValue, text, 0, selectionEnd);
        }

        this.updateValue(event, newValueStr, text, 'insert');
      }
    } else if (sign.isDecimalSign) {
      if (decimalCharIndex > 0 && selectionStart === decimalCharIndex) {
        this.updateValue(event, inputValue, text, 'insert');
      } else if (decimalCharIndex > selectionStart && decimalCharIndex < selectionEnd) {
        newValueStr = this.insertText(inputValue, text, selectionStart, selectionEnd);
        this.updateValue(event, newValueStr, text, 'insert');
      } else if (decimalCharIndex === -1 && this.maxFractionDigits) {
        newValueStr = this.insertText(inputValue, text, selectionStart, selectionEnd);
        this.updateValue(event, newValueStr, text, 'insert');
      }
    } else {
      const maxFractionDigits = this.numberFormat.resolvedOptions().maximumFractionDigits;
      const operation = selectionStart !== selectionEnd ? 'range-insert' : 'insert';

      if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
        if (selectionStart + text.length - (decimalCharIndex + 1) <= maxFractionDigits) {
          const charIndex =
            currencyCharIndex >= selectionStart
              ? currencyCharIndex - 1
              : suffixCharIndex >= selectionStart
                ? suffixCharIndex
                : inputValue.length;

          newValueStr =
            inputValue.slice(0, selectionStart) +
            text +
            inputValue.slice(selectionStart + text.length, charIndex) +
            inputValue.slice(charIndex);
          this.updateValue(event, newValueStr, text, operation);
        }
      } else {
        newValueStr = this.insertText(inputValue, text, selectionStart, selectionEnd);
        this.updateValue(event, newValueStr, text, operation);
      }
    }
  }

  insertText(value: string, text: string, start: number, end: number) {
    const textSplit = text === '.' ? text : text.split('.');

    if (textSplit.length === 2) {
      const decimalCharIndex = value.slice(start, end).search(this._decimal);
      this._decimal.lastIndex = 0;
      return decimalCharIndex > 0
        ? value.slice(0, start) + this.formatValue(text) + value.slice(end)
        : value || this.formatValue(text);
    } else if (end - start === value.length) {
      return this.formatValue(text);
    } else if (start === 0) {
      return text + value.slice(end);
    } else if (end === value.length) {
      return value.slice(0, start) + text;
    } else {
      return value.slice(0, start) + text + value.slice(end);
    }
  }

  deleteRange(value: string, start: number, end: number) {
    let newValueStr;

    if (end - start === value.length) newValueStr = '';
    else if (start === 0) newValueStr = value.slice(end);
    else if (end === value.length) newValueStr = value.slice(0, start);
    else newValueStr = value.slice(0, start) + value.slice(end);

    return newValueStr;
  }

  initCursor() {
    let selectionStart = this.input?.nativeElement.selectionStart;
    let inputValue = this.input?.nativeElement.value;
    const valueLength = inputValue.length;
    let index = null;

    // remove prefix
    const prefixLength = (this.prefixChar || '').length;
    inputValue = inputValue.replace(this._prefix, '');
    selectionStart = selectionStart - prefixLength;

    let char = inputValue.charAt(selectionStart);
    if (this.isNumeralChar(char)) {
      return selectionStart + prefixLength;
    }

    //left
    let i = selectionStart - 1;
    while (i >= 0) {
      char = inputValue.charAt(i);
      if (this.isNumeralChar(char)) {
        index = i + prefixLength;
        break;
      } else {
        i--;
      }
    }

    if (index !== null) {
      this.input?.nativeElement.setSelectionRange(index + 1, index + 1);
    } else {
      i = selectionStart;
      while (i < valueLength) {
        char = inputValue.charAt(i);
        if (this.isNumeralChar(char)) {
          index = i + prefixLength;
          break;
        } else {
          i++;
        }
      }

      if (index !== null) {
        this.input?.nativeElement.setSelectionRange(index, index);
      }
    }

    return index || 0;
  }

  onInputClick() {
    const currentValue = this.input?.nativeElement.value;

    if (!this.readonly && currentValue !== DomHandler.getSelection()) {
      this.initCursor();
    }
  }

  isNumeralChar(char: string) {
    if (
      char.length === 1 &&
      (this._numeral.test(char) ||
        this._decimal.test(char) ||
        this._group.test(char) ||
        this._minusSign.test(char))
    ) {
      this.resetRegex();
      return true;
    }

    return false;
  }

  resetRegex() {
    this._numeral.lastIndex = 0;
    this._decimal.lastIndex = 0;
    this._group.lastIndex = 0;
    this._minusSign.lastIndex = 0;
  }

  updateValue(
    event: Event,
    valueStr: Nullable<string>,
    insertedValueStr: Nullable<string>,
    operation: Nullable<string>
  ) {
    const currentValue = this.input?.nativeElement.value;
    let newValue = null;

    if (valueStr != null) {
      newValue = this.parseValue(valueStr);
      newValue = !newValue && !this.allowEmpty ? 0 : newValue;
      this.updateInput(newValue, insertedValueStr, operation, valueStr);

      this.handleOnInput(event, currentValue, newValue);
    }
  }

  handleOnInput(event: Event, currentValue: string, newValue: any) {
    if (this.isValueChanged(currentValue, newValue)) {
      this.input.nativeElement.value = this.formatValue(newValue);
      this.input?.nativeElement.setAttribute('aria-valuenow', newValue);
      this.updateModel(event, newValue);
      this.OnInput.emit({ originalEvent: event, value: newValue, formattedValue: currentValue });
    }
  }

  isValueChanged(currentValue: string, newValue: string) {
    if (newValue === null && currentValue !== null) {
      return true;
    }

    if (newValue != null) {
      const parsedCurrentValue =
        typeof currentValue === 'string' ? this.parseValue(currentValue) : currentValue;
      return newValue !== parsedCurrentValue;
    }

    return false;
  }

  validateValue(value: number | string) {
    if (value === '-' || value == null) {
      return null;
    }

    if (this.min != null && (value as number) < this.min) {
      return this.min;
    }

    if (this.max != null && (value as number) > this.max) {
      return this.max;
    }

    return value;
  }

  updateInput(
    value: any,
    insertedValueStr: Nullable<string>,
    operation: Nullable<string>,
    valueStr: Nullable<string>
  ) {
    insertedValueStr = insertedValueStr || '';

    const inputValue = this.input?.nativeElement.value;
    let newValue = this.formatValue(value);
    const currentLength = inputValue.length;

    if (newValue !== valueStr) {
      newValue = this.concatValues(newValue, valueStr || '');
    }

    if (currentLength === 0) {
      this.input.nativeElement.value = newValue;
      this.input.nativeElement.setSelectionRange(0, 0);
      const index = this.initCursor();
      const selectionEnd = index + insertedValueStr.length;
      this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
    } else {
      let selectionStart = this.input.nativeElement.selectionStart;
      let selectionEnd = this.input.nativeElement.selectionEnd;

      if (this.maxlength && newValue.length > this.maxlength) {
        newValue = newValue.slice(0, this.maxlength);
        selectionStart = Math.min(selectionStart, this.maxlength);
        selectionEnd = Math.min(selectionEnd, this.maxlength);
      }

      if (this.maxlength && this.maxlength < newValue.length) {
        return;
      }

      this.input.nativeElement.value = newValue;
      const newLength = newValue.length;

      if (operation === 'range-insert') {
        const startValue = this.parseValue((inputValue || '').slice(0, selectionStart));
        const startValueStr = startValue !== null ? startValue.toString() : '';
        const startExpr = startValueStr.split('').join(`(${this.groupChar})?`);
        const sRegex = new RegExp(startExpr, 'g');
        sRegex.test(newValue);

        const tExpr = insertedValueStr.split('').join(`(${this.groupChar})?`);
        const tRegex = new RegExp(tExpr, 'g');
        tRegex.test(newValue.slice(sRegex.lastIndex));

        selectionEnd = sRegex.lastIndex + tRegex.lastIndex;
        this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
      } else if (newLength === currentLength) {
        if (operation === 'insert' || operation === 'delete-back-single')
          this.input.nativeElement.setSelectionRange(selectionEnd + 1, selectionEnd + 1);
        else if (operation === 'delete-single')
          this.input.nativeElement.setSelectionRange(selectionEnd - 1, selectionEnd - 1);
        else if (operation === 'delete-range' || operation === 'spin')
          this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
      } else if (operation === 'delete-back-single') {
        const prevChar = inputValue.charAt(selectionEnd - 1);
        const nextChar = inputValue.charAt(selectionEnd);
        const diff = currentLength - newLength;
        const isGroupChar = this._group.test(nextChar);

        if (isGroupChar && diff === 1) {
          selectionEnd += 1;
        } else if (!isGroupChar && this.isNumeralChar(prevChar)) {
          selectionEnd += -1 * diff + 1;
        }

        this._group.lastIndex = 0;
        this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
      } else if (inputValue === '-' && operation === 'insert') {
        this.input.nativeElement.setSelectionRange(0, 0);
        const index = this.initCursor();
        selectionEnd = index + insertedValueStr.length + 1;
        this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
      } else {
        selectionEnd = selectionEnd + (newLength - currentLength);
        this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
      }
    }

    this.input.nativeElement.setAttribute('aria-valuenow', value);
  }

  concatValues(val1: string, val2: string) {
    if (val1 && val2) {
      const decimalCharIndex = val2.search(this._decimal);
      this._decimal.lastIndex = 0;

      if (this.suffixChar) {
        return (
          val1.replace(this.suffixChar, '').split(this._decimal)[0] +
          val2.replace(this.suffixChar, '').slice(decimalCharIndex) +
          this.suffixChar
        );
      } else {
        return decimalCharIndex !== -1
          ? val1.split(this._decimal)[0] + val2.slice(decimalCharIndex)
          : val1;
      }
    }
    return val1;
  }

  getDecimalLength(value: string) {
    if (value) {
      const valueSplit = value.split(this._decimal);

      if (valueSplit.length === 2) {
        return valueSplit[1]
          .replace(this._suffix, '')
          .trim()
          .replace(/\s/g, '')
          .replace(this._currency as RegExp, '').length;
      }
    }

    return 0;
  }

  onInputFocus(event: Event) {
    this.focused = true;
    this.OnFocus.emit(event);
  }

  onInputBlur(event: Event) {
    this.focused = false;

    const newValue = this.validateValue(this.parseValue(this.input.nativeElement.value));

    this.OnBlur.emit(event);

    this.input.nativeElement.value = this.formatValue(newValue);
    this.input.nativeElement.setAttribute('aria-valuenow', newValue);
    this.updateModel(event, newValue);
  }

  formattedValue() {
    const val = !this.value && !this.allowEmpty ? 0 : this.value;
    return this.formatValue(val);
  }

  updateModel(event: Event, value: any) {
    const isBlurUpdateOnMode = this._ngControl?.updateOn === 'blur';

    if (this.value !== value) {
      this.value = value;

      if (!(isBlurUpdateOnMode && this.focused)) {
        this.onModelChange(value);
      }
    } else if (isBlurUpdateOnMode) {
      this.onModelChange(value);
    }
    this.onModelTouched();
    this.updateControlValue();
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateControlValue();
    this.cd.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  get filled() {
    return this.value != null && this.value.toString().length > 0;
  }

  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  getFormatter() {
    return this.numberFormat;
  }
}
