<ng-template #errorsTemplate let-errors="errors">
  @if (errors) {
    @if (errors.pattern) {
      @if (regexMessage) {
        <span [innerHTML]="regexMessage"></span>
      } @else {
        <ng-container> Die Eingabe enthält ein unzulässiges Zeichen. </ng-container>
      }
    }
    @if (errors.required) {
      Bitte ergänzen Sie das Feld.
    }
    @if (errors.minlength) {
      Bitte ergänzen Sie dieses Feld mit einer Mindestlänge von
      {{ errors.minlength.requiredLength }} Zeichen.
    }
    @if (errors.maxlength) {
      Das Feld {{ fieldName }} besitzt eine maximale Länge von
      {{ errors.maxlength.requiredLength }} Zeichen.
    }
    @if (errors.min) {
      Das Feld {{ fieldName }} erfordert ein min. Wert von {{ errors.min.min }}.
    }
    @if (errors.max) {
      Das Feld {{ fieldName }} hat den maximalen Wert von {{ errors.max.max }} überschritten.
    }
    @if (errors.email) {
      Das Feld {{ fieldName }} muss eine gültige E-Mail-Adresse enthalten.
    }
    @if (errors.number) {
      Das Feld {{ fieldName }} sollte eine Zahl sein.
    }
    @if (errors.url) {
      Die Eingabe {{ fieldName }} sollte eine URL sein.
    }
    @if (errors.minStartOfContractDate) {
      Der Vertragsbeginn kann maximal 2 Monate rückdatiert werden.
    }
    @if (errors.minDate) {
      Das Datum muss am oder nach dem
      {{ errors.minDate?.value | date }} liegen.
    }
    @if (errors.maxDate) {
      Das Datum muss am oder vor dem
      {{ errors.maxDate?.value | date }} liegen.
    }
    @if (errors.matDatepickerFilter) {
      Das ausgewählte Datum ist nicht zulässig. Bitte wählen Sie ein anderes Datum.
    }
    @if (errors.date) {
      Das Feld {{ fieldName }} sollte ein Datum sein (TT.MM.JJJJ).
    }
    @if (errors.userExists) {
      Der Nutzer ist nicht vorhanden.
    }
    @if (errors.uniqueQuantity) {
      Es gibt bereits eine Anzahloption mit der gleichen Menge.
    }
    @if (errors.contactInformationRequired) {
      Bitte ergänze das Feld. Bitte gib mindestens eine Kontaktinformation an.
    }
    @if (errors.invalidPhoneNumber) {
      Bitte gib eine gültige Telefonnummer ein. Gültig sind nur die folgenden Zeichen: "+", "0-9".
    }
    @if (errors.incompletePhoneNumber) {
      Bitte gib eine vollständige Telefonnummer ein. Erforderlich sind min. 6 Zeichen.
    }
    @if (errors.invalidPostalCode) {
      Bitte gib eine gültige Postleitzahl ein. Sie muss genau 5 Ziffern enthalten.
    }
    @if (errors.notMatch) {
      Die Eingabe stimmt nicht überein.
    }
    @if (errors.iban) {
      Ungültige IBAN.
    }
    @if (errors.custom) {
      {{ errors.custom }}
    }
    <ng-container *ngIf="errors.firstDayOfYear">
      Das Datum darf nicht am 01.01. liegen
    </ng-container>
  }
</ng-template>

<ng-container
  *ngTemplateOutlet="errorsTemplate; context: { errors: control.errors }"
/>

<!--dirty: {{ control.dirty }} - -->
<!--invalid: {{ control.invalid }}  - -->
<!--touched: {{ control.touched }} - -->
