<header class="flex items-stretch justify-between space-x-4">
  <ng-content select="[logo]" />

  <ul>
    @for (link of links; track trackById($index)) {
      <li>
        <a
          [routerLink]="link.url"
          [routerLinkActiveOptions]="routerLinkActiveOptions"
          routerLinkActive="active"
          >{{ link.title }}</a
        >
      </li>
    }
  </ul>

  <ng-content select="[avatar]" />
</header>
