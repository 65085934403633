import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatTabsModule } from '@angular/material/tabs';
import type { IsActiveMatchOptions } from '@angular/router';
import { RouterLink, RouterLinkActive } from '@angular/router';

export interface InterfaceTabMenuComponentLinks<URLs = string> {
  url?: URLs;
  title: string;
}

@Component({
  selector: 'ui-tab-menu',
  standalone: true,
  imports: [MatTabsModule, RouterLink, RouterLinkActive],
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabMenuComponent {
  routerLinkActiveOptions: IsActiveMatchOptions = {
    paths: 'exact',
    fragment: 'ignored',
    matrixParams: 'ignored',
    queryParams: 'ignored',
  };

  @Input() links: InterfaceTabMenuComponentLinks[] = [];
  trackById = (i: number) => i;
}
